import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Formula 1 cars are the fastest cars in the world, they accelerate to 100km/h in approximately 2.6 seconds. One of these cars costs on average, 12.4 million dollars. In 2019 the revenue from formula 1 was over 2 billion dollars. One formula 1 car makes around 256,000 tons of carbon dioxide in a race season. This is the main consequence of formula 1 cars, and the faster they will go the more carbon dioxide they produce. The cars are what makes Formula 1 which means we have to use them. Formula 1 comes from people’s excitement of speed, which means there are thousands of engineers working day and night, thinking about how to make it go faster. The main factor which doesn’t allow cars to move faster is friction. Today I am going to tell you about how engineers try to lessen friction and use it to their advantage.`}</p>
    <p>{`Designing a formula 1 car is a very complex, and never-ending process. Before I will start explaining the design of the car you need to know 3 things; pressure, Newton’s 3ed law of motion, and airflow. Let’s start with describing pressure, air pressure is how dense the air molecules are with each other, in a specific area. Pressure might get higher due to: more molecules, less space, higher temperature. Another fact that you need to know is that the high-pressure air always goes to the low-pressure air, to balance out. This is called air movement and is also called aero(air)dynamics (movement)  Newton’s 3ed law of motion - Every action has an equal and opposite reaction.  What this means in the real world is that if you push something it will push you back with the same force.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/b7f47/06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7OVYGhcP/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECEQMQEhP/2gAIAQEAAQUCc4xPfGK6vXTP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAQUAAAAAAAAAAAAAAAAAAAERICEy/9oACAEBAAY/ArVjUf/EABsQAAIDAAMAAAAAAAAAAAAAAAARASExQWGR/9oACAEBAAE/IVmzGMYoXA7dMmXxHh2H/9oADAMBAAIAAwAAABC7L//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCH/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUXGBkf/aAAgBAQABPxBkEtGz6lrIVyiD47lqNiGhYuGIaUyTlfFA4//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "06",
            "title": "06",
            "src": "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/e5166/06.jpg",
            "srcSet": ["/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/f93b5/06.jpg 300w", "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/b4294/06.jpg 600w", "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/e5166/06.jpg 1200w", "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/d9c39/06.jpg 1800w", "/static/dbef7e50cb3f6a5bf6db55d9611cbbd2/b7f47/06.jpg 1804w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since you now know everything you need to know let’s start using that knowledge to understand an F1 car. Let’s start with explaining what the rear wing does. The rear wing’s main goal is to push air upwards, and as we learned if the air is getting pushed upwards, there must be an equal and opposite reaction. If the air is pushed upwards the car will get pushed downwards. Now I am going to explain how this happens in deeper details.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d48bfbbc3f79ae84725f26d13c3f553e/a20ae/image5.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7FM4GBYP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEgMQ/9oACAEBAAEFArvCnprzTFTHTP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABISD/2gAIAQEABj8CpE3n/8QAGRABAQEAAwAAAAAAAAAAAAAAAREAMVFh/9oACAEBAAE/IQopqZ7EhmoKRmYbt6znf//aAAwDAQACAAMAAAAQy8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qh//EABoQAQEBAQADAAAAAAAAAAAAAAERACFRYXH/2gAIAQEAAT8QKlCw5d9xjgyRSdHjIQuJS4aN6b//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/d48bfbbc3f79ae84725f26d13c3f553e/a20ae/image5.jpg",
            "srcSet": ["/static/d48bfbbc3f79ae84725f26d13c3f553e/f93b5/image5.jpg 300w", "/static/d48bfbbc3f79ae84725f26d13c3f553e/b4294/image5.jpg 600w", "/static/d48bfbbc3f79ae84725f26d13c3f553e/a20ae/image5.jpg 906w"],
            "sizes": "(max-width: 906px) 100vw, 906px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHaWoKiUT//xAAZEAEAAwEBAAAAAAAAAAAAAAABAAIREhP/2gAIAQEAAQUCtYqeowROmbNZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBABAAMBAAAAAAAAAAAAAAAAAQARICL/2gAIAQEABj8CtnIuf//EABkQAAMBAQEAAAAAAAAAAAAAAAABEZEQQf/aAAgBAQABPyGuYhaQkRcR5wsl6f/aAAwDAQACAAMAAAAQ6D//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCH/8QAHBABAQACAgMAAAAAAAAAAAAAAREAIUFxYYHB/9oACAEBAAE/EDgmZZzgTICug9vzIoHeFCLTyZvurJZxgSbd5//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg",
            "srcSet": ["/static/a66b8af6bf2e0d78ba66302155054868/f93b5/image2.jpg 300w", "/static/a66b8af6bf2e0d78ba66302155054868/b4294/image2.jpg 600w", "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg 902w"],
            "sizes": "(max-width: 902px) 100vw, 902px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When the car is in riding the air hits the rear wing at pretty much a straight line. This means some air will hit the top of the rear wing and continue to follow the wings curve until it ends. The air which hits the lower part of the wing will have a much harder journey because of the curve, and as you can see the air gets squeezed. As we learned if the air pressure will get higher if there is less space, which means the air on the bottom is faster than the air on the top. This means that the air on the bottom is at a lower pressure and the air on the bottom is at higher pressure. This means that the air on the top is hitting the rear wing downwards much harder than the air on the bottom hitting the rear wing upwards. This means that the overall force will be downwards. This is not the only factor that is making the overall force down. As you can see the air at the end of the rear wing is higher than before it hit the rear wing. This means the rear wing brought the air upwards, and since Newton’s 3rd law is still a factor, the air pushes the rear wing downwards. This means that if we tilt the rear wing upward we will have a greater downforce. In case you wondered why the air doesn’t keep going straight after it hit the bottom part of the rear wing, I will explain it now. You see when it goes straight that means there will be space between the air that went up in the air which went down.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "909px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1de652ea1ad3fa5f34e58a6292c208ac/358c9/image7.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7NMpGRUP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEhAR/9oACAEBAAEFAqpSl7XNM0zTP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAARASAh/9oACAEBAAY/AtGlFf/EABsQAAIDAAMAAAAAAAAAAAAAAAABEVFhQYGR/9oACAEBAAE/IZcniHdKuuWdI0NB2H//2gAMAwEAAgADAAAAEOvP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAgMAAAAAAAAAAAAAAAEAESFhUYGR/9oACAEBAAE/ECbKcBarD9MytdvBqO/CFZBNbj//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/1de652ea1ad3fa5f34e58a6292c208ac/358c9/image7.jpg",
            "srcSet": ["/static/1de652ea1ad3fa5f34e58a6292c208ac/f93b5/image7.jpg 300w", "/static/1de652ea1ad3fa5f34e58a6292c208ac/b4294/image7.jpg 600w", "/static/1de652ea1ad3fa5f34e58a6292c208ac/358c9/image7.jpg 909w"],
            "sizes": "(max-width: 909px) 100vw, 909px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If there is space between layers of air that means there is no or very little pressure of air. As we learned the high-pressure air will always go to the low-pressure air. This also is another reason why the air which went down is faster.  Let’s go back to tilting the rear wing, to get bigger downforce. The thing is that tilting the wing may not be the best choice because if the wing is tilted enough the momentum of the air might overpower the low-pressure area. This is called airflow separation. With the air being detached the average direction of the air will be lower than if we would just leave it as it was. With the air down wanting to go to the low-pressure area and continue in a straight line, it creates turbulence also known as turbulent flow. We can’t give up though and we must figure out a way to keep this tilt while maintaining the airflow connected to the rear wing.  The answer to that problem is vortices. A vortex is a spiral of air in the airflow. If we manage to make a vortex in the low-pressure area then we will be able to connect the down air to the rear wing and get rid of the turbulence air.  How do we make vortices? How does a car without vortices work?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "904px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a83bcc51a9909011f6c71242ff44dc6/469a4/image4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHTnSDYmH//xAAYEAEBAQEBAAAAAAAAAAAAAAABAAISIv/aAAgBAQABBQLXhHWlum6Z03//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAABBQAAAAAAAAAAAAAAAAABABESIDH/2gAIAQEABj8CkSsYV//EAB0QAAIBBAMAAAAAAAAAAAAAAAABERAhMWFBUXH/2gAIAQEAAT8hS6GpwpF5+fmjebztH//aAAwDAQACAAMAAAAQHw//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAAEFAAMAAAAAAAAAAAAAAAEAESExYRBxsf/aAAgBAQABPxAjlMBEfWREk5BW05isOlFwjsYX/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/3a83bcc51a9909011f6c71242ff44dc6/469a4/image4.jpg",
            "srcSet": ["/static/3a83bcc51a9909011f6c71242ff44dc6/f93b5/image4.jpg 300w", "/static/3a83bcc51a9909011f6c71242ff44dc6/b4294/image4.jpg 600w", "/static/3a83bcc51a9909011f6c71242ff44dc6/469a4/image4.jpg 904w"],
            "sizes": "(max-width: 904px) 100vw, 904px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "905px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a9af555160a11da9177157c76d8118a/280b9/image8.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezHBdAf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECERIT/9oACAEBAAEFAm1E7xuzTNM0z//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABMSD/2gAIAQEABj8CIyY//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMWEhQXH/2gAIAQEAAT8hiVcvpKmAveFlTybzef/aAAwDAQACAAMAAAAQP+//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QEX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAEFAQAAAAAAAAAAAAABEQAQITFBUWH/2gAIAQEAAT8QjkQtCnsyxaFnCXBi0XpNzInxrZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/2a9af555160a11da9177157c76d8118a/280b9/image8.jpg",
            "srcSet": ["/static/2a9af555160a11da9177157c76d8118a/f93b5/image8.jpg 300w", "/static/2a9af555160a11da9177157c76d8118a/b4294/image8.jpg 600w", "/static/2a9af555160a11da9177157c76d8118a/280b9/image8.jpg 905w"],
            "sizes": "(max-width: 905px) 100vw, 905px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let me 1st explain how a car without vortices works. When the air hits the car it splits into two parts, which means the rear wing only gets weak turbulent air. This creates another problem because the car is moving so quickly the 2 airways don’t merge in together and there ends up being one big low-pressure area. Since the air in the front of the car is high pressure, it goes to the low-pressure area making a drag, slowing the car down. You can also think of it as if the low-pressure area is a vacuum. If the low-pressure area is behind a car that means it’s pulling it back. Now we have 2 problems: the rear wing only gets weak turbulent air and the car is getting pulled back.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "903px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/882ec02ce9841f50c5e80c95b62cadc7/f6336/image1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHZpgDYoH//xAAZEAABBQAAAAAAAAAAAAAAAAABAAMREiD/2gAIAQEAAQUCJKs7OP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAAhAREgMnH/2gAIAQEABj8CRrFdHh//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQBhITH/2gAIAQEAAT8hBJHqzH25BZ6LzV1dXf/aAAwDAQACAAMAAAAQxw//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAICAwEAAAAAAAAAAAAAAQARYZEhMXHB/9oACAEBAAE/EHhanIQfZ4TIC/blbkrcQo7dzI7mR3P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/882ec02ce9841f50c5e80c95b62cadc7/f6336/image1.jpg",
            "srcSet": ["/static/882ec02ce9841f50c5e80c95b62cadc7/f93b5/image1.jpg 300w", "/static/882ec02ce9841f50c5e80c95b62cadc7/b4294/image1.jpg 600w", "/static/882ec02ce9841f50c5e80c95b62cadc7/f6336/image1.jpg 903w"],
            "sizes": "(max-width: 903px) 100vw, 903px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now I am going to explain how vortices solve this problem and how vortices are made. As you can see from the images the difference between them. The vortices help get all the strong wings to go to the rear wing and remove the low-pressure area behind the car. Our rear wing will be much more effective now and we will have no force pulling our car back. As you can see the vertices are a very useful thing. But how are they created?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "689px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a45549804a02e660529fc196aae3fdb8/86056/image3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdSO8K0UT//EABwQAAEDBQAAAAAAAAAAAAAAAAEAAhIDEBMhMf/aAAgBAQABBQKpklN4Q2LDn//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIRIiD/2gAIAQEABj8CyrRqJfH/xAAcEAEAAgEFAAAAAAAAAAAAAAABADERECFBUWH/2gAIAQEAAT8hAyJPahxc7uYAC9dgn//aAAwDAQACAAMAAAAQa8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCj/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAEDBQAAAAAAAAAAAAAAAQARIWEQQXGBkf/aAAgBAQABPxA0MsFeS4txB3uRnCgqW0QREEzAAAALBP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/a45549804a02e660529fc196aae3fdb8/86056/image3.jpg",
            "srcSet": ["/static/a45549804a02e660529fc196aae3fdb8/f93b5/image3.jpg 300w", "/static/a45549804a02e660529fc196aae3fdb8/b4294/image3.jpg 600w", "/static/a45549804a02e660529fc196aae3fdb8/86056/image3.jpg 689w"],
            "sizes": "(max-width: 689px) 100vw, 689px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHaWoKiUT//xAAZEAEAAwEBAAAAAAAAAAAAAAABAAIREhP/2gAIAQEAAQUCtYqeowROmbNZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBABAAMBAAAAAAAAAAAAAAAAAQARICL/2gAIAQEABj8CtnIuf//EABkQAAMBAQEAAAAAAAAAAAAAAAABEZEQQf/aAAgBAQABPyGuYhaQkRcR5wsl6f/aAAwDAQACAAMAAAAQ6D//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCH/8QAHBABAQACAgMAAAAAAAAAAAAAAREAIUFxYYHB/9oACAEBAAE/EDgmZZzgTICug9vzIoHeFCLTyZvurJZxgSbd5//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a",
            "title": "a",
            "src": "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg",
            "srcSet": ["/static/a66b8af6bf2e0d78ba66302155054868/f93b5/image2.jpg 300w", "/static/a66b8af6bf2e0d78ba66302155054868/b4294/image2.jpg 600w", "/static/a66b8af6bf2e0d78ba66302155054868/fc6fd/image2.jpg 902w"],
            "sizes": "(max-width: 902px) 100vw, 902px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Vortices are created by many pieces, for now, I will help you understand how one of them works. When the air flows into one of these pieces, they get split into two groups: low pressure and high pressure. As we know the high-pressure air wants to go to the low-pressure area. With this piece, the high-pressure air doesn’t have to do that after the air has passed over the wing. The high-pressure air can curl up over the edge of the wing, and get to the bottom of the low-pressure air.  From here the air will go in a circular motion like a screw and continue down the car. Of course, one-piece won’t have a huge effect that’s why the car has a lot of these in different parts of the car.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      